<template>
  <div class="app-container">
    <el-form
      ref="form"
      :model="{ form: form }"
      :rules="rules"
      label-width="150px"
    >
      <el-form-item label="试卷名" prop="form.name" :rules="rules.name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="试卷分类" prop="form.type" :rules="rules.type">
        <el-select
          v-model="form.type"
          placeholder="请选择试卷分类"
          class="filter-item"
        >
          <el-option label="随堂练习" :value="1" />
          <el-option label="课后作业" :value="2" />
          <el-option label="班级考试" :value="3" />
        </el-select>
      </el-form-item>
      <el-form-item
        label="学年"
        prop="form.school_year"
        :rules="rules.school_year"
      >
        <el-date-picker
          v-model="form.school_year"
          type="year"
          class="filter-item"
          value-format="yyyy"
          placeholder="选择学年"
          style="margin-bottom: 20px"
          :clearable="false"
          @change="changeSchoolYear"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="学期" prop="form.type" :rules="rules.semester">
        <el-select
          v-model="form.semester"
          placeholder="请选择学期"
          class="filter-item"
        >
          <el-option label="第一学期" :value="1" />
          <el-option label="第二学期" :value="2" />
        </el-select>
      </el-form-item>
      <el-form-item
        label="考试时长(分钟)"
        prop="form.duration"
        :rules="rules.duration"
      >
        <el-input v-model="form.duration"></el-input>
      </el-form-item>
      <el-form-item
        label="排序"
        prop="form.sort_weight"
        :rules="rules.sort_weight"
      >
        <el-input v-model="form.sort_weight"></el-input>
      </el-form-item>
      <el-form-item label="考试班级" required>
        <div class="box">
          <div
            class="box_item"
            v-for="(classItem, index) in form.classList"
            :key="index"
          >
            <el-form-item
              label="学校"
              :prop="'form.classList.' + index + '.school_id'"
              :rules="rules.school_id"
            >
              <el-select
                v-model="classItem.school_id"
                placeholder="请选择学校"
                style="margin-bottom: 20px"
                class="filter-item"
                @change="removeData($event, index), getCollegeList('', index)"
              >
                <el-option
                  v-for="item in school_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="学年"
              :prop="'form.classList.' + index + '.school_year'"
              :rules="rules.school_year"
            >
              <el-date-picker
                v-model="classItem.school_year"
                type="year"
                class="filter-item"
                value-format="yyyy"
                placeholder="选择学年"
                style="margin-bottom: 20px"
                :clearable="false"
                :disabled="true"
                @change="getCollegeList($event, index)"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="院系"
              :prop="'form.classList.' + index + '.college_id'"
              :rules="rules.college_id"
            >
              <el-select
                v-model="classItem.college_id"
                placeholder="请选择院系"
                style="margin-bottom: 20px"
                class="filter-item"
                @change="getGradeList($event, index)"
              >
                <el-option
                  v-for="item in college_list[index]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="年级"
              :prop="'form.classList.' + index + '.grade_id'"
              :rules="rules.grade_id"
            >
              <el-select
                v-model="classItem.grade_id"
                placeholder="请选择年级"
                style="margin-bottom: 20px"
                class="filter-item"
                @change="getmajorList($event, index)"
              >
                <el-option
                  v-for="item in grades_list[index]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="专业"
              :prop="'form.classList.' + index + '.major_id'"
              :rules="rules.major_id"
            >
              <el-select
                v-model="classItem.major_id"
                placeholder="请选择专业"
                style="margin-bottom: 20px"
                class="filter-item"
                @change="getClassList($event, index)"
              >
                <el-option
                  v-for="item in major_list[index]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="班级"
              :prop="'form.classList.' + index + '.class_id'"
              :rules="rules.class_id"
            >
              <el-select
                v-model="classItem.class_id"
                placeholder="请选择班级"
                style="margin-bottom: 20px"
                class="filter-item"
                @change="changeClass($event, index)"
              >
                <el-option
                  v-for="item in class_list[index]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <i class="el-icon-error" @click="remove(index)"></i>
          </div>
          <el-button type="primary" @click="addClass">添加一个班级</el-button>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" @click="submit" :loading="btnLoading">
          提交
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      btnLoading: false,
      form: {
        id: "",
        name: "",
        duration: "",
        sort_weight: "",
        type: "",
        semester: "",
        school_year: "",
        class_ids: [],
        classList: [
          {
            school_id: "", //学校
            college_id: "", //院系
            school_year: "", //学年
            grade_id: "", //年级
            major_id: "", //专业
            class_id: "", //班级
          },
        ],
      },
      rules: {
        name: [{ required: true, message: "请填写试卷名", trigger: "blur" }],
        duration: [
          { required: true, message: "请填写考试时长", trigger: "blur" },
        ],
        sort_weight: [
          { required: true, message: "请填写排序", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择试卷分类", trigger: "change" },
        ],
        semester: [
          { required: true, message: "请选择学期", trigger: "change" },
        ],
        school_id: [
          { required: true, message: "请选择学校", trigger: "change" },
        ],
        college_id: [
          { required: true, message: "请选择院系", trigger: "change" },
        ],
        school_year: [
          { required: true, message: "请选择学年", trigger: "change" },
        ],
        grade_id: [
          { required: true, message: "请选择专业", trigger: "change" },
        ],
        major_id: [
          { required: true, message: "请选择专业", trigger: "change" },
        ],
        class_id: [
          { required: true, message: "请选择班级", trigger: "change" },
        ],
      },
      school_list: [],
      college_list: [],
      grades_list: [],
      major_list: [],
      class_list: [],
      id: "",
    };
  },
  created() {
    this.getSchoolList();
    this.id = this.$route.query.id;
    if (this.id) this.getDetail(this.id);
  },
  mounted() {},
  methods: {
    changeClass(e, index) {
      this.form.classList[index].class_id = e;
      this.$forceUpdate(); //强制更新
    },
    changeSchoolYear() {
      this.form.classList.forEach((item) => {
        item.school_year = this.form.school_year;
        item.grade_id = null;
        item.major_id = null;
        item.class_id = null;
        item.college_id = null;
      });
      this.$forceUpdate(); //强制更新
    },
    removeData(e, index) {
      this.form.classList[index].college_id = null;
      // this.form.classList[index].school_year = null;
      this.form.classList[index].grade_id = null;
      this.form.classList[index].major_id = null;
      this.form.classList[index].class_id = null;
      // {
      //   school_id: e, //学校
      //   college_id: null, //院系
      //   school_year: null, //学年
      //   grade_id: null, //年级
      //   major_id: null, //专业
      //   class_id: null, //班级
      // };
      this.college_list[index] = [];
      this.grades_list[index] = [];
      this.major_list[index] = [];
      this.class_list[index] = [];
      this.form.classList[index].school_id = e;
      // this.getCollegeList();
      this.$forceUpdate(); //强制更新
    },
    getDetail(id) {
      request({
        url: "/api/teacherend/teacherPaper/detail",
        method: "get",
        params: {
          limit: 99999999999999,
          id: id,
        },
      }).then((response) => {
        // this.form.name = response.data.name;
        // this.form.duration = response.data.duration;
        // this.form.semester = response.data.semester;
        // this.form.type = response.data.type;
        // this.form.school_year = response.data.school_year;
        // this.form.sort_weight = response.data.sort_weight;
        // this.form.id = response.data.id;
        this.form = response.data;
        this.form.classList = [];
        console.log(response, "=========================");
        response.data.classes.forEach((item, index) => {
          this.form.classList.push({
            school_id: item.grade.school_id, //学校
            college_id: item.college_id, //院系
            school_year: item.school_year, //学年
            grade_id: item.grade_id, //年级
            major_id: item.major_id, //专业
            class_id: item.id, //班级
          });
          this.college_list[index] = [item.college];
          this.grades_list[index] = [item.grade];
          this.major_list[index] = [item.major];
          this.class_list[index] = [{ id: item.id, name: item.name }];
        });
      });
    },
    //删除考试班级
    remove(index) {
      if (this.form.classList.length - 1 == 0) {
        return;
      }
      this.form.classList.splice(index, 1);
      this.college_list.splice(index, 1);
      this.grades_list.splice(index, 1);
      this.major_list.splice(index, 1);
      this.class_list.splice(index, 1);
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          let data = this.form;
          console.log(data);
          data.class_ids = [];
          data.classList.forEach((item) => {
            data.class_ids.push(item.class_id);
            delete item.limit;
          });
          delete data.classList;
          request({
            url: "/api/teacherend/teacherPaper/store",
            method: "post",
            data: data,
          })
            .then((response) => {
              this.btnLoading = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.$router.replace({ path: "/question/testpaper" });
              let visitedViews = this.$store.state.tagsView.visitedViews;
              let index = visitedViews.findIndex(
                (item) => item.path == "/question/testpaper_edit"
              );
              this.$store.state.tagsView.visitedViews.splice(index, 1);
            })
            .catch(() => {
              this.btnLoading = false;
            });
        }
      });
    },
    addClass() {
      console.log(this.form);
      this.form.classList.push({
        school_id: "", //学校
        college_id: "", //院系
        school_year: this.form.school_year, //学年
        grade_id: "", //年级
        major_id: "", //专业
        class_id: "", //班级
      });
      // this.getSchoolList()
      this.$forceUpdate(); //强制更新
    },
    //获取学校列表
    getSchoolList() {
      request({
        url: "/api/teacherend/school/list",
        method: "get",
        params: {
          limit: 99999999999999,
        },
      }).then((response) => {
        this.school_list = response.data.data;
        // this.school_list.push(response.data.data);
      });
    },
    //获取院系列表
    getCollegeList(e, index) {
      // this.form.classList[index].limit = 999999999999999;
      // this.form.classList[index].college_id = null;
      // this.college_list[index] = [];
      // this.form.classList[index].grade_id = null;
      // this.grades_list[index] = [];
      // this.form.classList[index].major_id = null;
      // this.major_list[index] = [];
      // this.form.classList[index].class_id = null;
      // this.class_list[index] = [];
      request({
        url: "/api/teacherend/school/collegeList",
        method: "get",
        params: this.form.classList[index],
      }).then((response) => {
        if (this.college_list.length == this.form.classList.length) {
          this.college_list[index] = response.data.data;
        } else {
          this.college_list.push(response.data.data);
        }
        this.$forceUpdate(); //强制更新
      });
    },
    //获取年级
    getGradeList(e, index) {
      this.form.classList[index].grade_id = null;
      this.grades_list[index] = [];
      this.form.classList[index].major_id = null;
      this.major_list[index] = [];
      this.form.classList[index].class_id = null;
      this.class_list[index] = [];
      request({
        url: "/api/teacherend/school/gradeList",
        method: "get",
        params: this.form.classList[index],
      }).then((response) => {
        // this.grades_list = response.data.data;
        // this.grades_list.push(response.data.data);
        if (this.grades_list.length == this.form.classList.length) {
          this.grades_list[index] = response.data.data;
        } else {
          this.grades_list.push(response.data.data);
        }
        this.$forceUpdate(); //强制更新
      });
    },
    //获取专业
    getmajorList(e, index) {
      this.form.classList[index].class_id = null;
      this.class_list[index] = [];
      this.form.classList[index].major_id = null;
      this.major_list[index] = [];
      request({
        url: "/api/teacherend/school/majorList",
        method: "get",
        params: this.form.classList[index],
      }).then((response) => {
        if (this.major_list.length == this.form.classList.length) {
          this.major_list[index] = response.data.data;
        } else {
          this.major_list.push(response.data.data);
        }
        this.$forceUpdate(); //强制更新
      });
    },
    //获取班级
    getClassList(e, index) {
      this.form.classList[index].class_id = null;
      this.class_list[index] = [];
      // this.classes[index] = [];
      console.log(e, index);
      request({
        url: "/api/teacherend/school/classList",
        method: "get",
        params: { ...this.form.classList[index], is_class_class: 1 },
      }).then((response) => {
        // this.class_list = response.data.data;
        // this.class_list.push(response.data.data);
        if (this.class_list.length == this.form.classList.length) {
          this.class_list[index] = response.data.data;
        } else {
          this.class_list.push(response.data.data);
        }
        this.$forceUpdate(); //强制更新
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner {
  width: 280px !important;
}
.box {
  .box_item {
    width: 400px;
    border: 1px solid #dcdfe6;
    margin-bottom: 20px;
    padding: 20px;
    position: relative;
    .el-icon-error {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 28px;
      color: #606266;
    }
  }
  /deep/ .el-form-item__label {
    width: 50px !important;
  }
  /deep/ .el-form-item__content {
    margin-left: 50px !important;
  }
}
</style>